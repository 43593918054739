jQuery(document).ready(function($) {

    $('#menu a, #logo, #tovabb, .nav a, .fel').click(function(e) {
        var id = this.hash.substr(1);
        if (id.length > 0 && (id != "logo" || $(this).is(".slideol"))) {
            e.preventDefault();
            goToByScroll(id);
        }
    });

	$.ajaxSetup({
		headers: {
			'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
		}
	});

    /*var $grid = $('.grid').imagesLoaded( function() {
        // init Isotope after all images have loaded
        $('.grid').isotope({
            // set itemSelector so .grid-sizer is not used in layout
            itemSelector: '.grid-item',
            percentPosition: true
        });
    });*/

	$('.datepicker').datetimepicker({
		language: 'hu',
		weekStart: 1,
		autoclose: 1,
		todayHighlight: 1,
		startView: 2,
		minView: 0,
		maxView: 3,
		minuteStep: 30,
		format: 'yyyy-mm-dd hh:ii',
        pickerPosition: "bottom-left",
		keyboardNavigation: false
	});
	
	$('.datepicker2').datetimepicker({
		format: 'YYYY-MM-DD HH:mm',
		dayViewHeaderFormat: 'YYYY MMMM',
		locale: 'hu',
		stepping: 30,
		collapse: false,
		sideBySide: true,
		minDate: 'now',
		daysOfWeekDisabled: [0, 6],
		enabledHours: [8, 9, 10, 11, 12],
		widgetPositioning: {
			horizontal: 'auto',
			vertical: 'bottom'
		}
	});

	$('.datepicker2').each(function() {
		if ($(this).attr('value')) {
			$(this).data('DateTimePicker').date($(this).attr('value'));
		}
	});

	$('#nyitvatartas-es-bejelentkezes [name="helyszin"]').on('change', function() {
		var value = $(this).val(),
			ora = 15,
			$datum = $('#nyitvatartas-es-bejelentkezes .datepicker2').data('DateTimePicker'),
			hoursEnabled = $.map($datum.enabledHours(), function(element,index) {return Number(index)}),
			pos = $.inArray(ora, hoursEnabled);

		if (value == 'latogatokozpont') {
			if (pos == -1) {
				hoursEnabled.push(ora);
				$datum.enabledHours(hoursEnabled);
			}
		} else if (value == 'latogatokozpont-eromubejaras') {
			if (pos != -1) {
				hoursEnabled.splice(pos, 1);
				$datum.enabledHours(hoursEnabled);
			}
		}
	});

	$('#nyitvatartas-es-bejelentkezes').on('click', '.close', function(e) {
		e.preventDefault();
		$(this).parents('.uresdiv').fadeOut('fast');
	});

	$('#nyitvatartas-es-bejelentkezes').on('change', '[name="korosztaly"]', function(e) {
		e.preventDefault();
		var $target = $('[name="helyszin"][value="latogatokozpont-eromubejaras"]');
		if ($(this).val() == '6-11') {
			$target.attr('disabled', 'disabled').prop('disabled', true).removeAttr('checked').prop('checked', false);
		} else {
			$target.removeAttr('disabled').removeProp('disabled');
		}
	});
	
	$('#nyitvatartas-es-bejelentkezes [data-error]').each(function() {
		var $this = $(this),
			error = $this.data('error');

		if (error.length) {
			var buborek = '<div class="uresdiv"><div class="error-div"><dl>' +
				'<button type="button" class="close" aria-label="Close"><span aria-hidden="true">&times;</span></button>' +
//				'<dt>{{$model->labels($id)}}</dt>' +
				'<dd>' + error + '</dd>' +
				'</dl></div></div>';
			$this.append(buborek);
		}
	});

	$('.adatvedelmi-link').on('click', function(){
		$(this).parents('label').find('input[disabled]').attr('disabled', false);
	});

    var canvas, stage, exportRoot;

    canvas = document.getElementById("canvas");
    exportRoot = new lib.zold_zona_animacio();

    stage = new createjs.Stage(canvas);
    stage.addChild(exportRoot);
    stage.update();

    createjs.Ticker.setFPS(lib.properties.fps);
    createjs.Ticker.addEventListener("tick", stage);

    if($("#gmap").length){
        var Helyszin = {
            lat: 46.06713,
            lng: 18.26756
        };

        var map = new GMaps({
            div: '#gmap',
            lat: Helyszin.lat,
            lng: Helyszin.lng,
            disableDefaultUI: true,
            scrollwheel: false,
            mapTypeControl: true,
            mapTypeControlOptions: {
                mapTypeIds: [google.maps.MapTypeId.ROADMAP, google.maps.MapTypeId.SATELLITE],
                position: google.maps.ControlPosition.TOP_RIGHT,
                style: google.maps.MapTypeControlStyle.DROPDOWN_MENU
            },
            zoomControl: true,
            zoomControlOptions: {
                position: google.maps.ControlPosition.RIGHT_TOP,
                style: google.maps.ZoomControlStyle.SMALL
            }
        });

        map.addMarker({
            lat: Helyszin.lat,
            lng: Helyszin.lng,
            icon: "/img/marker.png"
        });

        $(window).on("resize", function(e){
            map.setCenter(Helyszin.lat, Helyszin.lng);
        });
    }

    $(".fancybox").fancybox({
        maxWidth	: "80%",
        maxHeight	: "80%",
        fitToView	: false,
        openEffect	: 'none',
        closeEffect	: 'none',
        scrolling   : 'no'


    });

	$('.scrollto').each(function() {
		var target = $(this).data('target');
		if (target != '') {
			goToByScroll(target);
		}
	});

    $('.navbar-collapse').find('a').click(function() {
        $(this).closest('.navbar-collapse').removeClass('in');
    });

	$('.limit_chk').each(function(){
		var ez = $(this);

		ez.delegate('.vetelkedo_chk','change',function(){
			var limit = ez.data('limit');
			if ($(this).is(':checked')) {
				var ossz = ez.children().find('.vetelkedo_chk:checked').length;
				if (ossz > limit) {
					$(this).removeAttr('checked');
				}
			}
		});
	});

	var $slides = $('#slides'),
		$imgs = $slides.find('img');
	if ($imgs.length) {
		var loaded = 0,
			$rendezheto = $('#rendezheto'),
			$message = $('.message'),
			completeHandler = function(number) {

				if (number == $imgs.length) {
					setTimeout(function() {
						clearInterval($slides.data().plugin_slidesjs.data.playInterval);

						function shuffle(o){
							for(var j, x, i = o.length; i; j = Math.floor(Math.random() * i), x = o[--i], o[i] = o[j], o[j] = x);
							return o;
						}

						$imgs = shuffle($imgs);
						$imgs.each(function() {
							$rendezheto.append($('<img src="' + $(this).attr('src') + '" id="item_' + $(this).data('id') + '" class="center-block img-responsive">'));
						});
						$slides.slideUp().empty();

						$rendezheto.sortable();
						$('#kuldes').show();
					}, 900);
				}
			},
			slidejsOptions = {
				width: 400,
				height: 200,
				navigation: {
					active: false
				},
				pagination: {
					active: false
				},
				effect: {
					fade: {
						speed: 20,
						crossfade: true
					}
				},
				play: {
					active: false,
					effect: 'fade',
					interval: 1000,
					auto: true,
					swap: false,
					pauseOnHover: false,
					restartDelay: 25000
				},
				callback: {
					complete: completeHandler
				}
			},
			kep_loaded = function() {
				loaded++;
				if (loaded < $imgs.length) {
					return false;
				}
				$message.html($('<a href="#slides" class="btn btn-primary hover-narancs">Játék!</a>').on('click', function(e) {
					// e.preventDefault();
					$slides.slidesjs(slidejsOptions);
					$(this).remove();
				}));
			};

		$imgs.each(function() {
			var img = new Image();
			img.src = $(this).attr('src');
			img.onload = function() {
				kep_loaded();
			}
		});

		$('#kuldes').on('click', function(e) {
			e.preventDefault();


			$.post('/gorgetos-jatek/sorrend', {
				data: $rendezheto.sortable('serialize')
			}, function(resp) {
				$rendezheto.empty();
				$('#kuldes').hide();
				if (resp.status == 'success') {
					$("#egyedi-link").attr("href",'/gorgetos-jatek?token=' + resp.token);
					$('#myModal').modal('toggle');
				} else {
					var $reload = $('<a href="#" class="btn btn-primary hover-narancs">Újra</a>').on('click', function(e) {
						e.preventDefault();
						document.location.reload();
					});
					$message.html('Sajnáljuk, most nem sikerült<br>').append($reload);
				}
			});
		});
	}
	
	function honnan_change() {
		var $this = $('input[name="honnan"]:checked'),
			$target = $('#honnan_text');
	
		if ($this.val() == 'internet' || $this.val() == 'hirdetes') {
			$target.parents('.form-group').slideDown().end().removeAttr('disabled').removeProp('disabled')
		} else {
			$target.parents('.form-group').slideUp().end().attr('disabled', 'disabled').prop('disabled', true)
		}
		
		if ($target.data('labels')[$this.val()]) {
			$target.parents('.form-group').find('label').html($target.data('labels')[$this.val()] + '*');
		}
	}
	
	$('input[name="honnan"]').on('change', honnan_change);
	$('input[name="honnan"]').each(honnan_change);

	$('#editModal_1').modal('toggle');

});

function goToByScroll(id){
    $('html,body').animate({scrollTop: $("#" + id).offset().top}, 'slow');
}

flowplayer.conf = {
    splash: true
};